import React from 'react'
import { dateFormat } from '../helpers/formats'
import PublicationList from './publicationList'
import styles from '../styles/stockCard.module.css'
import TickIcon from '../assets/tick.svg'
import CloseIcon from '../assets/close.svg'
import LaunchIcon from '../assets/launch.svg'

const StockCard = ({name, benchmarked, availability, inactivationMethod, gisaid, concentration, source, commonName, viralRange, publications, msds, batch, slug}) => {
    
    if(publications) {
        publications.forEach(publication => {
            publication.data.Date = new Date(publication.data.Date)
        })
        publications.sort((a, b) => a.data.Date - b.data.Date).reverse()
    }
    
    return (
        <div className={styles.stockCard}>
            <div className={styles.content}>
                <h2>{name}</h2>
                <div className={styles.info}>
                    <div>{'Benchmarked:'}</div><div>{benchmarked?.map((benchmark, i) => benchmark? <TickIcon key={i}/> : <CloseIcon key={i}/>)}</div>
                    {
                        commonName && (<><div>{'Variant:'}</div><div>{commonName}</div></>)
                    }
                    {
                        availability && (<><div>{'Availavility:'}</div><div>{availability}</div></>)
                    }
                    {
                        source && (<><div>{'Source:'}</div><div>{source}</div></>)
                    }
                    {
                        inactivationMethod && (<><div>{'Inactivation method:'}</div><div>{inactivationMethod}</div></>)
                    }
                    {
                        gisaid && (<><div>{'GISAID:'}</div><div>{gisaid}</div></>)
                    }
                    {
                        concentration && (<><div>{'Concentration:'}</div><div>{concentration}</div></>)
                    }
                    {
                        viralRange && (<><div>{'Viral range:'}</div><div>{viralRange}</div></>)
                    }
                    {
                        batch && (<><div>{'Batch #:'}</div><div>{batch}</div></>)
                    }
                    {
                        msds && (
                            <>
                                <div>{'MSDS:'}</div>
                                    <div className={styles.msdsList}>
                                        {
                                        msds.map(msds => (
                                            <a key={msds.id} href={msds.data.File?.[0]?.url} target={'_blank'} rel={'noreferrer'} className={styles.msds}><LaunchIcon/>{msds.data.Name} ({dateFormat(new Date(msds.data.Date))})</a>
                                        ))
                                    }
                                </div>
                            </>
                        )
                    }
                </div>
                <PublicationList publications={publications} max={3}/>
            </div>
        </div>
    )
}

export default StockCard