import React, { useState, useEffect } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { graphql } from 'gatsby'
import Filters from '../components/filters'
import Layout from '../components/layout'
import PromptButton from '../components/promptButton'
import Message from '../components/message'
import StockCard from '../components/stockCard'
import styles from '../styles/stocks.module.css'
import IconStock from '../assets/icon-stock.svg'

const StockList = ({ data }) => {

    const {allAirtableStock:stock} = data
    const [currentTag] = useQueryParam('tag', StringParam)
    const [scroll, setScroll] = useState(0)
    const [search, setSearch] = useState('')
    const tags = [...new Set(stock.nodes.map(stock => stock.data.Source)), ...new Set(stock.nodes.map(stock => stock.data.Inactivation_method).flat()), ...new Set(stock.nodes.map(stock => stock.data.Common_name))].flat().filter(filter => filter).sort()

    const variants = [...new Set(stock.nodes.map(item => item.data.Name).filter(variant => variant))]
    const groupedStock = variants.map(variant => {
        const items = stock.nodes.filter(item => item.data.Name === variant)
        const news = items.map(item => item.data.Related_news).flat().filter(news => news)
        const newsIds = [...new Set(news.map(news => news.id))]
        const msds = items.map(item => item.data.MSDS).flat().filter(msds => msds)
        const msdsIds = [...new Set(msds.map(msds => msds.id))]
        return ({
            Name: variant,
            Benchmarked: [...new Set(items.map(item => item.data.Benchmarked))],
            Availability_date: [...new Set(items.map(item => item.data.Availability_date))],
            Common_name: [...new Set(items.map(item => item.data.Common_name))],
            Inactivation_method: [...new Set(items.map(item => item.data.Inactivation_method).flat())],
            Viral_range: [...new Set(items.map(item => item.data.Viral_range).flat())],
            GISAID: [...new Set(items.map(item => item.data.GISAID))],
            Source: [...new Set(items.map(item => item.data.Source))],
            Slug: [...new Set(items.map(item => item.data.Slug))],
            Related_news: newsIds.map(id => news.find(news => news.id === id)),
            MSDS: msdsIds.map(id => msds.find(msds => msds.id === id)),
        })
    })

    const filteredStock = groupedStock
        .filter(stock => currentTag === undefined || stock.Source.includes(currentTag) || stock.Inactivation_method.includes(currentTag) || stock.Common_name.includes(currentTag))
        .filter(stock => search === '' || stock.Name.toLowerCase().includes(search.toLocaleLowerCase()))
        .sort((a, b) => a.Name?.localeCompare(b.Name))

    const HandleMaterialRequest = () => {
        window.open('https://airtable.com/shrHLcNU8vYg6uILZ', '_blank')
    }

    useEffect(() => {
        window.scrollTo(0, scroll)
	})

    return (
		<Layout>
    		<div className={styles.header}>
                <h1>Stock</h1>
                <PromptButton primary={true} handleClick={HandleMaterialRequest}>Request materials</PromptButton>
            </div>
            <Filters path={`/stock`} tags={tags} currentTag={currentTag} search={search} handleClick={() => setScroll(window.scrollY)} handleSearch={value => setSearch(value)}/>
            {
                filteredStock.length > 0? (
                    <div className={styles.stock}>
                        {
                            filteredStock.map((stock, i) => <StockCard key={i} name={stock.Name} benchmarked={stock.Benchmarked} availability={stock.Availability_date?.join(', ') ?? undefined} inactivationMethod={stock.Inactivation_method?.join(', ') ?? undefined} gisaid={stock.GISAID?.join(', ') ?? undefined} concentration={stock.Concentration?.join(', ') ?? undefined} source={stock.Source?.join(', ') ?? undefined} commonName={stock.Common_name?.join(', ') ?? undefined} viralRange={stock.Viral_range?.join(', ') ?? undefined} publications={stock.Related_news} msds={stock.MSDS} slug={stock.Slug}/>)
                        }
                    </div>
                ) : (
                    <Message Icon={IconStock} content={'Stock not found'}/>
                )
            }
		</Layout>
	)
}

export const query = graphql`
    query {
		allAirtableStock(sort: {order: ASC, fields: data___Name}) {
            nodes {
                data {
                    Name
                    Slug
                    Benchmarked
                    Availability_date
                    Inactivation_method
                    Common_Name
                    Isolate
                    GISAID
                    Concentration
                    Source
                    Viral_range
                    Related_news {
                        id
                        data {
                            Title
                            Source
                            Source_type
                            Author
                            Date
                        }
                    }
                    Common_Name
                    MSDS {
                        id
                        data {
                            Date
                            Name
                            File {
                                url
                          }
                        }
                    }
                    Batch_number
                }
            }
        }
    }
`

export default StockList
